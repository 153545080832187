<template>
  <div class="newEmail">
    <div class="newEmailTop">
      <p class="eTitle">{{ $t("label.emailtemplate.new") }}</p>
      <p class="eContent">{{ $t("label.emailtemplate.selecttype") }}</p>
    </div>
    <div>
      <div class="seleteTemplete">
        <div class="radioPosi">
          <div class="marginEvery">
            <span class="spanType">{{ $t("label.text") }}</span>
            <el-radio v-model="radio" label="1">{{ "" }}</el-radio>
          </div>
          <div class="marginEvery">
            <span class="spanType">HTML</span>
            <el-radio v-model="radio" label="2">{{ "" }}</el-radio>
          </div>
          <div class="marginEvery">
            <el-button plain @click="nextStep">{{
              $t("label.tabpage.next")
            }}</el-button>
            <el-button plain @click="cancelNewTem">{{
              $t("label.tabpage.cancel")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "1",
    };
  },
  methods: {
    // 下一步
    nextStep() {
      this.$emit("selectTemType", this.radio);
    },
    // 取消
    cancelNewTem() {
      this.$emit("cancelNewTemplete");
    },
  },
};
</script>
<style lang="scss" scoped>
.newEmail {
  .newEmailTop {
    padding: 16px 10px 0 10px;
    height: 91px;
    border-bottom: 1px #dedcda solid;
    .eTitle {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .eContent {
      font-size: 16px;
      color: #333333;
      max-width: 808px;
      margin-top: 20px;
    }
  }
}
.seleteTemplete {
  display: flex;
  justify-content: center;
  .radioPosi {
    display: flex;
    flex-direction: column;
    width: 200px;
    text-align: center;
    .marginEvery {
      margin-top: 16px;
      .spanType {
        display: inline-block;
        text-align: right;
        margin-right: 10px;
        width: 60px;
      }
    }
  }
}
</style>