<template>
  <div>
    <el-dialog
      :title="headerProp"
      :visible.sync="newFolderToProp"
      width="35%"
      customClass="customWidth"
      :close-on-click-modal="false"
      :before-close="newFolderCancel"
    >
      <div class="title_name">
        <el-form ref="form" :model="form" class="demo-form-inline" label-width>
          <el-form-item
            :label="$t('label.foldername')"
            prop="name"
            :rules="[
              {
                required: true,
                message: this.$i18n.t('vue_label_report_foldernameisrequired'),
                trigger: ['blur'],
              },
            ]"
          >
            <div class="folderName">
              <el-input v-model="form.name" maxlength="255"></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$t('label.foldervisitlimit')">
            <el-select v-model="form.permission">
              <el-option :label="$t('label.readonlys')" value="0"></el-option>
              <el-option :label="$t('label.readwrite')" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 访问文件夹权限 -->
      <folderpermissions
        ref="folderAccessRef"
        :permissionInfo="permissionInfo"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="newFolderCancel">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button type="primary" @click="newFolderConfirm('form')">{{
          $t("label.tabpage.save")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as ReportObjectApi from "./api";
// 文件夹访问权限
import folderpermissions from "@/views/systemSettings/components/communicationTemplate/components/EmailComponents/Folder/folderpermissions.vue";
import * as emailTemplate from "../../../../../systemSettingsApi";
export default {
  props: {
    newFolderTo: {
      type:Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "",
    },
    folderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newFolderToProp: this.newFolderTo,
      headerProp: this.header,
      folderIdProp: this.folderId,
      form: {
        name: "", // 文件夹名称
        permission: "", // 访问权限
      },
      permissionInfo: {
        user: "", // 可见对象
        userList: [], // 用户
        roleList: [], // 角色
        roleAndSubList: [], // 角色及内部下属
        groupList: [], // 公用小组
        partnerRoleList: [], // 合作伙伴角色
        partnerUserList: [], // 合作伙伴用户
      },
    };
  },
  components: {
    folderpermissions,
  },
  watch: {
    newFolderToProp: function () {
      if (this.newFolderToProp) {
        this.form = {};
        setTimeout(() => {
          this.$refs.folderAccessRef.formSelectFol = {
            user: "", // 可用共享者
            role: "", // 选择的角色范围
            keyword2: "", // 对共享者的搜索字段
          };
          this.$refs.folderAccessRef.allRoles = [];
          this.$refs.folderAccessRef.allSelectedRoles = [];
          this.$refs.folderAccessRef.tabCurArr = {}; // 清空选中项
        }, 10);
        if (this.folderIdProp) {
          // 编辑文件夹
          this.queryTemplateFolderById();
        } else if (!this.folderIdProp) {
          // 新建文件夹
          this.getNewReportFolders();
        }
      }
    },
  },
  methods: {
    // 点击编辑文件夹
    
    queryTemplateFolderById() {
      let params = {
        id: this.folderIdProp,
      };
      emailTemplate.queryTemplateFolderById(params).then((res) => {
        this.form = {
          name: res.data.folder[0].name, // 文件夹名称
          permission: res.data.folder[0].purview, // 访问权限
        };
        let allSelectedRoles = [];
        // 获取已选择的所有用户
        if (res.data.selectUserList) {
          res.data.selectUserList.map((item) => {
            allSelectedRoles.push(item);
          });
        }
        if (res.data.selectRoleList) {
          res.data.selectRoleList.map((item) => {
            allSelectedRoles.push(item);
          });
        }
        if (res.data.selectRoleAndSubList) {
          res.data.selectRoleAndSubList.map((item) => {
            allSelectedRoles.push(item);
          });
        }
        if (res.data.selectGroupList) {
          res.data.selectGroupList.map((item) => {
            allSelectedRoles.push(item);
          });
        }
        if (res.data.selectPartnerRoleList) {
          res.data.selectPartnerRoleList.map((item) => {
            allSelectedRoles.push(item);
          });
        }
        if (res.data.selectPartnerUserList) {
          res.data.selectPartnerUserList.map((item) => {
            allSelectedRoles.push(item);
          });
        }
        this.permissionInfo = {
          id: this.folderIdProp,
          user: res.data.folder[0].viewtype, // 可见对象
          userList: res.data.userList, //用户
          roleList: res.data.roleList, // 角色
          roleAndSubList: res.data.roleAndSubList, // 角色及内部下属
          groupList: res.data.groupList, // 公用小组
          partnerRoleList: res.data.partnerRoleList, // 合作伙伴角色
          partnerUserList: res.data.partnerUserList, // 合作伙伴用户
          allSelectedRoles: allSelectedRoles, // 已选对象
        };
      });
    },

    // 点击新建文件夹
    
    async getNewReportFolders() {
      this.form = {
        name: "",
        permission: "0",
      };
      let { data } = await ReportObjectApi.getNewReportFolder();
      this.permissionInfo = {
        id: this.folderIdProp,
        user: "0", // 可见对象
        userList: data.userList, //用户
        roleList: data.roleList, // 角色
        roleAndSubList: data.roleAndSubList, // 角色及内部下属
        groupList: data.groupList, // 公用小组
        partnerRoleList: data.partnerRoleList, // 合作伙伴角色
        partnerUserList: data.partnerUserList, // 合作伙伴用户
        allSelectedRoles: [],
      };
    },
    // 新建编辑确认
    newFolderConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let folderUser = []; // 文件夹分配的指定用户
          if (this.$refs.folderAccessRef.allSelectedRoles) {
            this.$refs.folderAccessRef.allSelectedRoles.map((item) => {
              folderUser.push(item.id);
            });
          }
          let params = {};
          // 编辑
          if (this.folderIdProp) {
            params = {
              id: this.folderIdProp,
              name: this.form.name,
              purview: this.form.permission,
              accessibleuserset: folderUser.join(",") || "",
              foldertype: "email",
              viewtype: this.$refs.folderAccessRef.formSelectFol.user,
            };
          }
          // 新建
          else if (!this.folderIdProp) {
            params = {
              name: this.form.name,
              purview: this.form.permission,
              accessibleuserset: folderUser.join(",") || "",
              foldertype: "email",
              viewtype: this.$refs.folderAccessRef.formSelectFol.user,
            };
          }

          emailTemplate.saveTemplateFolder(params).then((res) => {
            if (res.result) {
              this.$message({
                message: this.$i18n.t("label.search.saveok"),
                type: "success",
              });
              this.newFolderCancel();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 关闭弹框
    newFolderCancel() {
      this.$refs.form.resetFields();
      this.$refs.folderAccessRef.formSelectFolClear();
      this.$emit("newFolderCancel");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .customWidth {
  min-width: 636px;
}
.newFolderDia {
  ::v-deep .el-dialog__body {
    height: 520px;
  }
}
.title_name {
  margin-bottom: 10px;
  ::v-deep .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      .el-select {
        width: 260px;
      }
    }
    .el-form-item:first-child {
      margin-right: 42px;
      .el-input {
        width: 261px;
      }
    }
    .el-form-item__error {
      padding-top: 0;
      padding-left: 2px;
    }
  }
}
::v-deep .el-form {
  .el-form-item__label {
    line-height: 16px;
    color: #242424;
    text-align: left;
  }
}
::v-deep .el-input__inner {
  height: 28px !important;
  // border: 1px solid #dcdcdc;
}
::v-deep .el-input__icon {
  height: 28px;
  line-height: 28px;
}
</style>