import { render, staticRenderFns } from "./createEmailTemp.vue?vue&type=template&id=928c7bfe&scoped=true&"
import script from "./createEmailTemp.vue?vue&type=script&lang=js&"
export * from "./createEmailTemp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "928c7bfe",
  null
  
)

export default component.exports