<!--
  文件夹访问权限
-->
<template>
  <div class="access_permissions">
    <div class="permissions_input">
      <el-form
        ref="formSelectFol"
        :inline="true"
        :model="formSelectFol"
        class="demo-form-inline"
      >
        <!-- 可见对象 -->
        <el-form-item
          :label="$t('front-kefu-moudle-v1-canuse-obj')"
          prop="user"
        >
          <el-select v-model="formSelectFol.user">
            <el-option
              :label="$t('label.foldervisitlimit1')"
              value="0"
            ></el-option>
            <el-option
              :label="$t('label.foldervisitlimit2')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('label.foldervisitlimit3')"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 范围 -->
        <el-form-item :label="$t('vue_label_report_range')" prop="role">
          <el-select
            v-model="formSelectFol.role"
            placeholder
            :disabled="formSelectFol.user === '0' || formSelectFol.user === '1'"
            @change="changeRole"
          >
            <el-option
              v-for="(item, index) in roles"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 搜索关键字 -->
    <div class="permissions_search">
      <el-input
        v-model="formSelectFol.keyword2"
        :placeholder="$t('label.placeholder')"
        :disabled="formSelectFol.user === '0' || formSelectFol.user === '1'"
        @input="searchKeyWord"
        suffix-icon="el-icon-search"
      ></el-input>
      <div>{{ $t("label.shareusers") }}</div>
    </div>
    <div class="border_content">
      <!-- 可见对象 -->
      <div class="left_info">
        <div
          class="each_info"
          v-for="(item, index) in allRoles"
          :key="index"
          @click="tabCurArr = item"
          :class="{ active: tabCurArr.id === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 操作按钮: 增加、删除 -->
      <div class="middle_butts">
        <div>{{ $t("label.add") }}</div>
        <el-button
          size="mini"
          @click="addToRight"
          :disabled="
            JSON.stringify(tabCurArr) === '{}' ||
            allSelectedRoles.some((item) => item.id === tabCurArr.id)
          "
          >>></el-button
        >
        <el-button
          size="mini"
          @click="addToLeft"
          :disabled="
            JSON.stringify(tabCurArr) === '{}' ||
            allRoles.some((item) => item.id === tabCurArr.id)
          "
          >&lt;&lt;</el-button
        >
        <div>{{ $t("button_partner_delete") }}</div>
      </div>
      <!-- 可用用户 -->
      <div class="right_infos">
        <div
          class="each_info"
          v-for="(item, index) in allSelectedRoles"
          :key="index"
          @click="tabCurArr = item"
          :class="{ active: tabCurArr.id === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissionInfo: {
      // 可用共享者列表和共享用户列表以及选择的可用共享者
      type: Object,
      default: ()=> {},
    },
  },
  data() {
    return {
      permissionInfoProp: this.permissionInfo,
      formSelectFol: {
        user: "", // 可见对象
        role: "", // 选择的角色范围，默认设置为用户
        keyword2: "", // 对共享者的搜索字段
      },
      roles: [
        // 权限范围
        {
          label: this.$i18n.t("label.tabpage.userz"),
          value: "0",
        },
        {
          label: this.$i18n.t("label.tabpage.rolez"),
          value: "1",
        },
        {
          label: this.$i18n.t("label.report.share.roleandsub"),
          value: "2",
        },
        {
          label: this.$i18n.t("label.publicgroup"),
          value: "3",
        },
        {
          label: this.$i18n.t("label.roleofpartner"),
          value: "4",
        },
        {
          label: this.$i18n.t("label.partneruser"),
          value: "5",
        },
      ],
      allRoles: [], // 所有共享者列表
      allSelectedRoles: [], // 所有已选择的共享者列表
      allRolesSearch: [], // 所有可见对象列表，用来搜索，始终是全部
      tabCurArr: {}, // 选中的角色
    };
  },
  watch: {
    permissionInfoProp: function () {
      this.formSelectFol.user = this.permissionInfoProp.user;
      this.allSelectedRoles = this.permissionInfoProp.allSelectedRoles;
    },
    // 监听选择的可用共享者user
    "formSelectFol.user": function () {
      if (this.formSelectFol.user === "2") {
        // 只有下列用户可访问此文件夹
        this.formSelectFol.role = "0"; // 默认选择用户
        this.changeRole("0");
      } else if (
        this.formSelectFol.user === "1" ||
        this.formSelectFol.user === "0"
      ) {
        this.formSelectFol.keyword2 = "";
        this.formSelectFol.role = "";
        this.allRoles = [];
      }
    },
  },

  methods: {
    // 清空表单
    
    formSelectFolClear() {
      this.allRoles = [];
      this.allSelectedRoles = [];
      this.$refs.formSelectFol.resetFields();
      this.formSelectFol.keyword2 = "";
    },
    // 切换角色
    changeRole(value) {
      this.formSelectFol.keyword2 = "";
      switch (value) {
        case "0": // 用户
          this.allRoles = this.permissionInfoProp.userList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "1": // 角色
          this.allRoles = this.permissionInfoProp.roleList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "2": // 角色及内部下属
          this.allRoles = this.permissionInfoProp.roleAndSubList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "3": // 公用小组
          this.allRoles = this.permissionInfoProp.groupList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "4": // 合作伙伴角色
          this.allRoles = this.permissionInfoProp.partnerRoleList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "5": // 合作伙伴用户
          this.allRoles = this.permissionInfoProp.partnerUserList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
      }
      this.allRolesSearch = JSON.parse(JSON.stringify(this.allRoles)); // 深拷贝共享者列表集合
    },
    // 搜索关键字
    searchKeyWord() {
      this.allRoles = this.allRolesSearch.filter(
        (item) => item.name.indexOf(this.formSelectFol.keyword2) > -1
      );
    },
    // 添加进此共享用户
    addToRight() {
      // 添加至右侧，从左侧移除
      this.allSelectedRoles.push(this.tabCurArr);
      let index = this.allRoles.findIndex(
        (item) => item.id === this.tabCurArr.id
      );
      this.allRoles.splice(index, 1);
      this.tabCurArr = {}; // 清空选中项
    },
    // 从共享用户中移除
    addToLeft() {
      // 添加至左侧，从右侧移除
      this.allRoles.push(this.tabCurArr);
      let index = this.allSelectedRoles.findIndex(
        (item) => item.id === this.tabCurArr.id
      );
      this.allSelectedRoles.splice(index, 1);
      this.tabCurArr = {}; // 清空选中项
    },
  },
};
</script>

<style lang="scss" scoped>
.border_content {
  display: flex;
  align-items: center;
  .left_info,
  .right_infos {
    padding: 7px 0 10px 9px;
    width: 260px;
    height: 283px;
    overflow-y: auto;
    border: 1px solid #d8dde6;
    border-radius: 4px;
    .each_info {
      color: #333333;
      margin-bottom: 2px;
      padding: 2px;
      cursor: pointer;
    }
    .userActive {
      background-color: #c3c4c5;
      color: #fff;
    }
  }
  .active {
    background-color: #c3c4c5;
    color: #fff !important;
  }
  .middle_butts {
    margin: 0 10px;
    color: #333333;
    display: flex;
    flex-direction: column;
  }
  div {
    margin-bottom: 8px;
  }
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 4px;
    margin-left: 0;
    margin-bottom: 8px;
  }
}
.access_permissions {
  .permissions_title01 {
    margin: 10px 0;
    color: #080707;
  }
  .permissions_input {
    ::v-deep .el-form {
      display: flex;
      .el-form-item {
        margin-bottom: 6px;
        display: flex;
        flex-direction: column;
      }
      .el-form-item:first-child {
        margin-right: 42px;
        .el-input {
          width: 261px;
        }
      }
      .el-form-item:last-child {
        .el-input {
          width: 260px;
        }
      }
    }
  }
  .permissions_search {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    color: #333333;
    .el-input {
      width: 261px;
      margin-right: 44px;
    }
  }
}
</style>